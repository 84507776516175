import * as React from "react"
import { SEO } from "../../components/seo"
import Layout from '../../components/vi/layout';
import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby";
import { Player, BigPlayButton } from 'video-react';
import "video-react/dist/video-react.css"; // import css

import heroBanner from '../../images/hero-banner-bg.jpg';
import tagline from '../../images/tagline.svg';
import kimCutout from '../../images/kim-cutout.png';


const IndexPage = (props) => {

  return (
    <Layout pathname={props.location.pathname}>
      <h1 className="sr-only">Kim Nguyen-Penaloza tranh cử vào Quốc hội</h1>
      <section className="h-[600px] md:h-screen bg-primary bg-no-repeat bg-cover bg-center relative text-center" style={{ backgroundImage: `url(${heroBanner})`}}>

        <div className="w-[100px] md:w-[200px] absolute top-20 right-4"><StaticImage src="../images/ca-dem-endorsement.png" alt="California Democratic Party Endorsed" placeholder="blurred" layout="constrained" width={300} /></div>

        <div className="absolute w-11/12 md:w-[900px] left-1/2 -translate-x-1/2 bottom-40 z-30">
          <img src={tagline} placeholder="none" layout="fullWidth" alt="One of us. For all of Us" className="animate__animated animate__fadeInUp drop-shadow-lg" />
        </div>
        <img src={kimCutout} placeholder="none" layout="fullWidth" alt="" className="absolute bottom-0 max-w-10/12 md:max-w-[600px] h-[90%] z-20 left-1/2 -translate-x-1/2" />
      </section>
      <section className="bg-primary">
        <div className="container pb-20 mx-auto">
            <div className="w-full px-4 lg:px-0 lg:w-8/12 mx-auto drop-shadow-xl rounded-2xl overflow-hidden">
            <Player
              playsInline
              poster="/video/kbn-congress-poster.png"
              preload="auto"
            >
              <source src="/video/kbn-congress-main-captioned.webm" type="video/webm; codecs=vp9,vorbis" />
              <source src="/video/kbn-congress-main-captioned.mp4" type="video/mp4" />
              <BigPlayButton position="center" />
            </Player>
            </div>
        </div>
      </section>
      <section className="text-white bg-primary-dark relative lg:flex">
        <div className="lg:w-1/2 h-[700px] lg:h-[600px] relative overflow-hidden group">
          <div className="absolute inset-y-0 inset-x-0 z-10 bg-gradient-to-t from-black/80 flex-col justify-end flex items-center text-center">
            <div className="lg:px-24 lg:pb-20 pb-10">
              <ScrollAnimation animateIn="animate__fadeIn">
                <p className="font-heading font-bold text-4xl text-secondary mb-2">Tiểu Sử</p>
                <p className="text-lg font-light text-white drop-shadow-sm leading-relaxed  mb-5">là con của gia đình một người tị nạn Việt Nam và một người di dân Mexico, câu chuyện của cô Kim là một câu chuyện tiêu biểu của California về tinh thần hy sinh, làm việc chăm chỉ, và tận tụy với cộng đồng.</p>
                <Link to="/about-kim" className="animate__animated animate__fadeInLeft animate__delay-1s font-bold text-[#3D3D3D] bg-gradient-to-r from-secondary via-secondary-light to-secondary text-base lg:text-xl rounded-full py-2 px-12 text-center transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100">
                  Tìm hiểu thêm về Kim
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
                  </svg>
                </Link>
              </ScrollAnimation>
            </div>
          </div>
          <div className="absolute inset-y-0 inset-x-0 bg-[url('../images/about-kim-hero-mobile.jpg')] lg:bg-[url('../images/about-kim-hero.jpg')] bg-top lg:bg-center bg-no-repeat bg-cover z-0 group-hover:scale-110 transition-transform ease-linear duration-200"></div>
        </div>
        <div className="lg:w-1/2 h-[700px] lg:h-[600px] relative overflow-hidden group">
          <div className="absolute inset-y-0 inset-x-0 z-10 bg-gradient-to-t from-black/80 flex-col justify-end flex items-center text-center">
            <div className="lg:px-24 pb-20">
              <ScrollAnimation animateIn="animate__fadeIn">
                <p className="font-heading font-bold text-4xl text-secondary mb-2">Cách Chúng Ta Sẽ Thắng</p>
                <p className="text-lg font-light text-white drop-shadow-sm leading-relaxed  mb-5">Kim Nguyễn-Penaloza đại diện cho thế hệ lãnh đạo tiếp nối của Hoa Kỳ.</p>
                <Link to="/how-we-win" className="animate__animated animate__fadeInLeft animate__delay-1s font-bold text-[#3D3D3D] bg-gradient-to-r from-secondary via-secondary-light to-secondary text-base lg:text-xl rounded-full py-2 px-12 text-center transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100">
                  Đọc thêm
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
                  </svg>
                </Link>
              </ScrollAnimation>
            </div>
          </div>
          <div className="absolute inset-y-0 inset-x-0 bg-[url('../images/platform-hero.jpg')] bg-center bg-no-repeat bg-cover z-0 group-hover:scale-110 transition-transform ease-linear duration-200"></div>
        </div>
        
      </section>
    </Layout>
  )
}

export default IndexPage

export const Head = ({ location }) => <SEO pathname={location.pathname} />
